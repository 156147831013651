<template>
  <div class="full-content-loader" :class="{ hide: !show }">
    <div class="container-content form-container container">
      <div class="page-header">
        <div class="d-none d-sm-block">
          <vue-content-loading :width="300" :height="10">
            <rect x="0" y="0" rx="4" ry="4" width="100" height="10" />
          </vue-content-loading>
        </div>
        <div class="d-block d-sm-none">
          <vue-content-loading :width="300" :height="20">
            <rect x="0" y="0" rx="4" ry="4" width="250" height="20" />
          </vue-content-loading>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-xl-8 offset-md-2">
          <vue-content-loading :width="400" :height="260">
            <circle cx="30" cy="30" r="30" />
            <rect x="75" y="13" rx="4" ry="4" width="100" height="15" />
            <rect x="75" y="37" rx="4" ry="4" width="50" height="10" />
            <rect x="0" y="85" rx="4" ry="4" width="120" height="10" />
            <rect x="0" y="100" rx="4" ry="4" width="400" height="24" />
            <rect x="0" y="140" rx="4" ry="4" width="120" height="10" />
            <rect x="0" y="155" rx="4" ry="4" width="400" height="24" />
            <rect x="0" y="200" rx="4" ry="4" width="400" height="24" />
          </vue-content-loading>
        </div>
        <div class="col-12 col-xl-8 offset-md-2">
          <vue-content-loading :width="400" :height="200">
            <rect x="0" y="0" rx="4" ry="4" width="400" height="200" />
          </vue-content-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: { VueContentLoading },
  computed: {
    show() {
      return this.$store.state.showFullContentLoader
    }
  }
}
</script>

<style lang="scss" scoped>
.full-content-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1020;
}
.hide {
  display: none;
}
</style>
