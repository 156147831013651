import get from 'lodash/get'
import toLower from 'lodash/toLower'

export const serviceMixin = {
  computed: {
    isSelectedServiceOptionValidWithMaid: function() {
      if (get(this.bookingForm, 'method') === 0 || !get(this.bookingForm, 'services', null)) {
        return true
      }
      // console.log(get(this.bookingForm, 'maid.service_types', []));
      const maidServices = get(this.bookingForm, 'maid.service_types', [])
      const selectedServices = get(this.bookingForm, 'services.value', '').split('_') || []
      if (maidServices.length === 0) {
        return true
      }
      return selectedServices.every(service => maidServices.includes(service))
    },
    notProvidedServicesList: function() {
      if (this.isSelectedServiceOptionValidWithMaid) return []
      const maidServices = get(this.bookingForm, 'maid.service_types', [])
      const selectedServices = get(this.bookingForm, 'services.value', '').split('_') || []
      return selectedServices.filter(service => !!service && !maidServices.includes(service))
    },
    notProvidedServicesError: function() {
      if (this.isSelectedServiceOptionValidWithMaid) return ``
      const servicesTranslation = this.notProvidedServicesList.map(service =>
        toLower(this.$t(`services.${toLower(service)}`))
      )
      if (toLower(this.$i18n.locale) === 'th') {
        return `แม่บ้านคนนี้ไม่ได้ให้บริการ${servicesTranslation.join(', ')}`
      }
      return `This maid does not provide ${servicesTranslation.join(', ')} service.`
    }
  }
}
