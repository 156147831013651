<template>
  <div class="d-lg-flex mb-lg-3 d-block">
    <div class="d-none d-lg-flex">
      <BookingStep :step="step" />
    </div>
    <div class="step-divider d-flex align-items-center d-lg-none py-3  " >
      <span></span>
      <p class="step">
        {{ step }}
      </p>
      <span></span>
    </div>
    <div class="flex-fill pt-2 position-relative" style="max-width: 100%;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BookingStep from './BookingStep'
export default {
  components: { BookingStep },
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  mounted () {
    console.log(this.$router.history.current.name)
  },
  computed: {
    isBookingMultiPackage() {
      let x = false
      if(this.$router.history.current.name === "MyBookingMultiPackage"){
        x=true
      }
      return x
    }
  },
}
</script>

<style scoped lang="scss">
.step-divider {
  width: 100%;
  span {
    display: block;
    position: relative;
    height: 2px;
    width: 100%;
    background-color: #afb1b0;
  }
  p {
    color: #afb1b0;
    margin: 0 36px;
    font-size: 1.25rem;
  }
}
</style>
