<template>
  <div>
    <b-form-group v-if="hasErrors">
      <p
        class="text-danger font-weight-normal text-small mb-0"
        style="font-size: 16px;"
        v-for="(error, index) in filteredErrors"
        :key="index"
      >
        - {{ error }}
      </p>

      <p class="text-danger font-weight-normal text-small mt-3" style="font-size: 16px;">
        {{ $t('warning.please_select_another_maid_or_change_the_menu') }}
      </p>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    filteredErrors: function() {
      return Array.isArray(this.errors) ? this.errors.filter(error => !!error) : []
    },
    hasErrors: function() {
      return this.filteredErrors.length > 0
    }
  }
}
</script>

<style></style>
