<template>
  <div class="d-flex">
    <div class="d-block" style="width:22px;">
      <h1 style="color: #afb1b0" class="text-right">{{ step }}</h1>
    </div>
    <span class="step-line ml-3 mr-4"></span>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="css" scoped>
.step-line {
  display: block;
  width: 4px;
  margin-top: 10px;
  background: #afb1b0;
}
</style>
